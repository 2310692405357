<template>
  <div>
    <div class="va-row">
      <div class="flex xs12 md12">
        <vuestic-widget>
          <div class="d-flex justify-content-between d">
            <el-input
              style="margin-bottom: 10px; width: 150px;"
              v-model="filters[0].value"
              placeholder="Pesquisar"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
              ></i>
            </el-input>
            <button
              @click="$router.push({name:'cadastrar-funcionario'})"
              class="btn btn-primary btn-micro pull-right"
            >{{'Cadastrar' | translate}}</button>
          </div>

          <div class="">
            <data-tables
              :data="funcionarios"
              :filters="filters"
              :action-col="actionCol"
              :table-props="tableProps"
              v-loading="loading"
            >
              <el-table-column
                prop="nome"
                label="Nome"
                sortable="custom"
                width="400"
              />
              <el-table-column
                prop="cpf"
                label="CPF"
                sortable="custom"
                width="180"
              />
            </data-tables>
          </div>
        </vuestic-widget>
      </div>
    </div>
    <!-- modal para deletar -->
    <vuestic-modal
      :show.sync="show"
      ref="staticModal"
      v-on:ok="deletarFuncionario(funcionario.id)"
    >
      <div slot="title">Excluir</div>
      <div>Você deseja excluir o funcionario <b>{{funcionario.nome}}</b>?</div>
    </vuestic-modal>
    <!-- -->
  </div>
</template>

<script>
import ordenacao from './ordenacao';

export default {
  name: 'lista-funcionarios',
  data() {
    return {
      show: true,
      loading: false,
      funcionario: {},
      funcionariosSelecionado: null,
      funcionarios: [],
      filters: [
        {
          value: '',
          prop: ['nome', 'cpf'],
        },
      ],
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'EDITAR/EXCLUIR',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              icon: 'el-icon-edit',
              type: 'primary',
              circle: true,
            },
            handler: (row) => {
              this.$router.push({ name: 'editar-funcionario', params: { id: row.user } });
            },
          },
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              this.funcionario = row;
              this.$refs.staticModal.open();
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getFuncionarios();
  },
  methods: {
    getFuncionarios() {
      this.loading = true;
      this.$axios.get('/api/funcionario.json').then((res) => {
        this.loading = false;
        this.funcionarios = (res.data).sort(ordenacao.ordenarObjetosPorNome);
      });
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
    deletarFuncionario(id) {
      this.$axios.delete(`/api/funcionario/${id}.json`).then(() => {
        this.message('success', 'Funcionario deletado com sucesso!!!');
        this.getFuncionarios();
      }).catch(() => this.message('error', 'Erro ao deletar!!! Verifique se existe(m) cadastro(s) vinculado(s) a este funcionario.'));
    },
    message(tipo, msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type: tipo,
        title: msg,
        showConfirmButton: false,
        timer: 3500,
      });
    },
  },
};
</script>
<style>
.d {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
